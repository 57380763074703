import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

import './style.scss'

const About = () => (
  <section className="about">
    <Container>
      <Row>
        <Col lg="6">
          <div className="embed-responsive embed16by9">
            <iframe
              src="https://www.youtube.com/embed/p6FFNYgMuFY"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              title="Sobre o talk of the Town"
            />
          </div>
        </Col>
        <Col lg="6">
          <div className="text">
            <h2>PARA SOLTAR A LÍNGUA E ABRIR A CABEÇA</h2>
            <p>
              Talk of the Town é uma escola de línguas que promove
              debates e cursos sobre temas do mundo contemporâneo.
              Nossa metodologia é inovadora: trabalha o idioma
              através da emoção. Novos temas são escolhidos a cada
              semana para mover, inspirar, desafiar, fazer pensar.
              Você pratica o idioma, amplia seu vocabulário, passa
              a se sentir seguro e preparado para discutir as
              questões do momento, em qualquer situação. Depois de
              mais de 20 anos funcionando em Ipanema, Rio de
              Janeiro, inauguramos nossa versão online.
            </p>
            <Link to="/register/" className="button center">
              Inscreva-se
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default About
