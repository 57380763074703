import React from 'react'
import './style.scss'
import { Container, Row, Col } from 'reactstrap'

const ExplanationSection = (props) => (
  <section
    className={`explanation ${props.className} ${
      props.grayBackground ? 'gray' : ''
    } ${props.whiteBackground ? 'white' : ''}`}
  >
    <Container fluid>
      {props.right && (
        <Row className="flex-column-reverse flex-lg-row">
          <Col lg="6">
            <div className="text">{props.children}</div>
          </Col>
          <Col
            lg="6"
            className="bg"
            style={{
              backgroundImage: `url(${props.imageBackground})`,
            }}
          />
        </Row>
      )}
      {props.left && (
        <Row>
          <Col
            lg="6"
            className="bg"
            style={{
              backgroundImage: `url(${props.imageBackground})`,
            }}
          />
          <Col lg="6">
            <div className="text">{props.children}</div>
          </Col>
        </Row>
      )}
      {props.full && (
        <Container>
          <Row>
            <Col>
              <div className="text">{props.children}</div>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  </section>
)

export default ExplanationSection
