import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import Carousel from 'react-multi-carousel'
import { format, addDays } from 'date-fns'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import About from '../components/About'
import ExplanationSection from '../components/ExplanationSection'
import SliderDebates from '../components/SliderDebates'
import DebatesBox from '../components/DebatesBox'
import { LOADER, GETURL } from '../utils/constants'

import 'react-multi-carousel/lib/styles.css'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const IndexPage = () => {
  const [weekDebates, setWeekDebates] = useState([])
  const [isLoading, setLoading] = useState(true);
  const [topDebates, setTopDebates] = useState([])
  const [isTopLoading, setTopLoading] = useState(true);
  const [courses, setCourses] = useState([])
  const [isCourseLoading, setCourseLoading] = useState(true);

  useEffect(() => {
    async function getWeekDebates() {
      try {
        const startDate = format(new Date(), 'dd/MM/yyyy')
        const endDate = format(addDays(new Date(), 6), 'dd/MM/yyyy')

        const requestWeekDebates = await axios.get(
          `${GETURL}/classes`, {
            params: {
              startDate,
              endDate,
            },
          },
        );
        // Primeiro: data = axios; Segundo: data : endpoint
        setWeekDebates(requestWeekDebates.data.data)
        setLoading(false);
      } catch (error) {
        console.log('Erro: ', error.message)
      }
    }

    async function getTop50() {
      try {
        const requestTop50 = await axios.get(`${GETURL}/debates`, {
          params: {
            top50: true,
            order: 'desc',
            orderby: 'date',
            limit: 9,
          },
        });

        setTopDebates(requestTop50.data.debates)
        setTopLoading(false);
      } catch (error) {
        console.log(error.message)
      }
    }

    async function getCourses() {
      try {
        const requestCourses = await axios.get(`${GETURL}/courses`, {
          params: {
            order: 'desc',
            orderby: 'date',
            limit: 9,
          },
        });

        setCourses(requestCourses.data.data)
        setCourseLoading(false);
      } catch (error) {
        console.log(error.message)
      }
    }

    getWeekDebates()
    getCourses()
    getTop50()
  }, [])

  return (
    <Layout>
      <Seo title="Debates em inglês | Talk of the Town" />

      <Hero
        text={['Update your language', <br />, 'Update your mind']}
        isHome
      />

      <About />

      <ExplanationSection
        imageBackground={require('../images/conferencia.jpg')}
        left
      >
        <h2 className="title">Debates in English</h2>
        <span className="subtitle">
          Venha pensar em inglês no Talk of the Town
        </span>
        <p>
          Todas as semanas, grupos com perfil, interesses e nível de
          inglês semelhantes se reúnem online com um
          professor/moderador para debater assuntos que são o talk of
          the town. Os encontros estão abertos a alunos com nível de
          inglês intermediário ou avançado e também funcionam no
          formato de aulas particulares, com a mesma mecânica. Venha
          reciclar o inglês e ampliar sua visão de mundo. Veja abaixo
          os temas da semana e participe.
        </p>
        <span className="cta-text">O primeiro debate é gratis!</span>
        <div className="text-center">
          <Link to="/register/" className="button">
            Inscreva-se
          </Link>
          <Link to="/debates-in-english/" className="button">
            Saiba mais
          </Link>
        </div>
      </ExplanationSection>

      <SliderDebates
        sectionTitle="New Debates - This Week e Coming Soon"
        buttonUrl="/debates/"
        buttonText="All debates by category"
      >
        <Carousel
          swipeable={false}
          draggable={false}
          arrows={false}
          showDots
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlaySpeed={1000}
          keyBoardControl
          containerClass="carousel-container row"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={3}
        >
          {isLoading && (
            <div className="loadingSpinner"><img src={LOADER} alt="Carregando" /></div>
          )}
          {weekDebates.length === 0 && (
            <p>Nenhum debate cadastrado essa semana!</p>
          )}
          {weekDebates.map((item) => {
            const {
              _id, get_image, title, description,
            } = item;
            return (
              <DebatesBox
                key={_id}
                widthClass="col-12"
                background={get_image}
                title={title}
                description={description}
                buttonUrl={`single/${_id}`}
                buttonText="Saiba mais"
                wordLimit="300"
              />
            )
          })}
        </Carousel>
      </SliderDebates>

      <ExplanationSection
        imageBackground={require('../images/talks-and-courses.jpeg')}
        right
      >
        <h2 className="title">Talks & Courses</h2>
        <span className="subtitle">
          Cursos e palestras em diversos idiomas
        </span>
        <p>
          Além de Debates in English, onde o aluno vai para falar, o
          Talk of the Town oferece cursos e palestras online com
          professores/experts em temas culturais: história da arte,
          cinema, fotografia, filosofia, literarura, história
          contemporânea. Os Talks & Courses são ministrados em vários
          idiomas, inclusive em português, e têm a duração média de 3
          a 4 encontros. Você pode assistir ao vivo ou na versão
          gravada, on demand.
        </p>
        <p>
          Confira abaixo a programação dos cursos online ao vivo. Ou
          faça aqui sua assinatura para assistir quando e quantas
          vezes quiser.
        </p>
        <Link to="/talks-and-courses/" className="button center">
          Compre agora
        </Link>
      </ExplanationSection>

      <SliderDebates
        sectionTitle=""
        buttonUrl="/courses/"
        buttonText="All Courses"
      >
        <Carousel
          swipeable={false}
          draggable={false}
          arrows={false}
          showDots
          responsive={responsive}
          ssr
          infinite
          autoPlaySpeed={1000}
          keyBoardControl
          containerClass="carousel-container row"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={3}
        >
          {isCourseLoading && (
            <div className="loadingSpinner"><img src={LOADER} alt="Carregando" /></div>
          )}
          {courses.length === 0 && (
            <p>Nenhum curso cadastrado!</p>
          )}
          {courses.map((item) => {
            const {
              _id, get_image, title, description,
            } = item;
            return (
              <DebatesBox
                key={_id}
                widthClass="col-12"
                background={get_image}
                title={title}
                description={description}
                buttonUrl={`single/${_id}`}
                buttonText="Saiba mais"
                wordLimit="300"
              />
            )
          })}
        </Carousel>
      </SliderDebates>

      <ExplanationSection
        imageBackground={require('../images/imagem_4.jpeg')}
        left
      >
        <h2 className="title">Debates for Teachers & Schools</h2>
        <span className="subtitle">
          Nosso conteúdo exclusivo para professores, profissionais e
          instituições de ensino
        </span>
        <p>
          Aqui os profissionais e escolas de línguas encontram uma
          biblioteca com centenas de Debates in English, divididos em
          categorias e níveis de dificuldade. Cada debate analisa um
          assunto polêmico da atualidade e inclui: vídeo, texto,
          vocabulário detalhado, pesquisas de referências e roteiro de
          perguntas para fomentar as discussões em sala de aula.
        </p>
        <p>
          Todo esse material pode ser acessado online tanto pelo aluno
          quanto pelo professor. Nosso conteúdo é adaptável à
          metodologia de cada escola e de cada profissional, para
          facilitar a construção de um curriculum criativo e também
          personalizado.
        </p>
        <p>
          Nossa metodologia é pioneira: detém o US Copyright
          Certificate, vem sendo testada e aprovada por décadas no Rio
          e Janeiro e está totalmente alinhada com as habilidades e
          ferramentas de educação no século XXI. Experimente!
        </p>
        <div className="text-center">
          <Link to="/debates-for-teachers/" className="button">
            I’m a Teacher
          </Link>
          <Link to="/debates-for-schools/" className="button">
            I’m a School
          </Link>
        </div>
      </ExplanationSection>

      <SliderDebates
        sectionTitle="Top 50 Debates"
        buttonUrl="/top-50/"
        buttonText="All top 50 debates"
      >
        <Carousel
          swipeable={false}
          draggable={false}
          arrows={false}
          showDots
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlaySpeed={1000}
          keyBoardControl
          containerClass="carousel-container row"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={3}
        >
          {isTopLoading && (
            <div className="loadingSpinner"><img src={LOADER} alt="Carregando" /></div>
          )}
          {topDebates.map((top) => {
            const {
              _id, get_image, title, description,
            } = top;
            return (
              <DebatesBox
                key={_id}
                widthClass="col-12"
                background={get_image}
                title={title}
                description={description}
                buttonUrl={`single/${_id}`}
                buttonText="Saiba mais"
                wordLimit="300"
              />
            );
          })}
        </Carousel>
      </SliderDebates>
    </Layout>
  )
}

export default IndexPage
